import React, { memo } from 'react'
import { useTheme } from '@mui/material'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Caption from '@atoms/text/Caption'

interface ContentImageProps {
  image: IGatsbyImageData
  imageAlt?: string
  caption?: string
}

const ContentImage = ({
  image,
  imageAlt,
  caption,
  ...props
}: ContentImageProps) => {
  const theme = useTheme()
  return (
    <>
      <GatsbyImage image={image} alt={imageAlt ? imageAlt : ''} {...props} />
      {caption && <Caption text={caption} />}
    </>
  )
}

export default memo(ContentImage)
